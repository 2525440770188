#legend {
    width: fit-content;
    margin: 0 auto;
    padding-top: 25px;
}

#legend > p {
    width: fit-content;
}

#legend > div {
    display: flex;
    justify-content: center;
    align-content: center;
}

#legend span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: solid black 1px;
    margin-right: 10px;
}

#legend-unavailable {
    background-color: lightgray;
}