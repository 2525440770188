.date-div {
    font-size: 1.1em;
    margin: 5px;
    border: solid gray 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 10%;
}

h1 {
    font-size: 2em;
}

.padding {
    border: none;
}

.disabled {
    background-color: lightgray;
}

.calender-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 17em;
    margin: 10px auto;
}

#increment-decrement {
    width: 70px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin: 10px auto;
}

#month {
    width: fit-content;
    margin: 0 auto;
}

.next-previous {
    width: 30px;
    height: 30px;
}

#top {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
}