#reservationDirections p {
    width: fit-content;
    margin: 5px auto;
}

#reservationDirections p:nth-of-type(1) {
    margin-top: 25px;
}

#reservationDirections li {
    margin: 10px;
}