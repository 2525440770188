.nav-link {
    margin: auto 10px;
}

.navbar-brand {
    margin-right: 50px;
}

.custom-active {
    color: white;
    font-weight: bold;
    font-size: 18px;
}

#facebookIcon {
    width: 50px;
    height: 50px;
    margin-right: 70px;
    margin-left: auto;
}

#facebookIcon img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

@media screen and (max-width: 991px) {
    #facebookIcon {
        margin-left: 30px;
    }
}