.carousel {
    max-width: 100%;
    max-height: 600px;
    margin: 0 auto 0px auto;
    z-index: 3;
}

.carousel-item img {
    object-fit: cover;
    width: 100%;
    max-height: 600px;
}

.carousel-caption {
    background-color: #293241;
}

.carousel-caption h3 {
    width: fit-content;
    margin: 5px auto;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: black;
}

#home-text {
    max-width: 1100px;
    margin: 20px auto;
}

#info-card-div {
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
}


