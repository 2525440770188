footer {
    margin-top: 50px;
    background-color: #264653;
    color: white;
    padding-bottom: 30px;
    padding-left: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

a {
    color: white;
}

h3 {
    margin-top: 15px;
    font-size: 18px;
}

footer div {
    margin: 20px;
    width: fit-content;
    min-width: 300px;
}