#campgroundRules p, #listGroup{
    width: 90%;
    margin: 20px auto;
}

#campgroundRules h2 {
    width: fit-content;
    margin: 15px auto;
}

#campgroundRules p {
    padding-left: 15px;
}