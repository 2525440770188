.amenity-bubble {
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 16px;
    align-items: center;
    cursor: default;
    background-color: #e0e0e0;
    margin: 3px;
}

.card {
    width: 30rem;
    height: auto;
    margin: 30px;
}

#lodging-cards {
    display: flex;
    flex-wrap: wrap;
}

.card button {
    margin: 10px;
}

.card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

h3 {
    font-size: 14px;
}

#priceList {
    cursor: pointer;
    color: black;
    text-decoration: underline solid black 1px;
    font-size: 18px;
    margin: 25px;
}

.btn-primary {
    background-color: #264653;
    border: solid #264653 1px;
}

.btn-primary:hover {
    background-color: #264653;
}

.campground-span {
    display: inline-block;
}


